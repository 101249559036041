.userMenu {
    position: relative;
    width: 200px;
    height: 200px;
    padding: 10px;
    border-radius: 15px;
    background-color: black;
    color:white;
    text-align: center;
}

.userMenu h4 {
    font-size: 1.4em;
}

.userMenu a {
    color: white;
}

.userMenu button {
    border: none;
    background-color: inherit;
    color: inherit;
}

.userMenu button:hover {
    color: #0056b3;
    text-decoration: underline;
}

.userMenu .userInfoLink {
    margin-top: 20px;
    padding: 0;
}


@media screen and (max-width: 700px)
{
    .userMenu {
        width: 100%;
        height: 250px;
    }

    .userMenu .userInfoLink {
        width: 30%;
    }

}