.presentationSection #dogOnTheBed {
    object-position: 0 100%;
}

.informationSection {
    font-size: 1.6rem;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.informationLines {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cultureSection {
    position: relative;
    font-size: 1.6rem;
}

.cultureSection img {
    width: 100%;
}

.cultureSection .cultureContain {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
    min-width: 200px;
    padding: 20px;
    color: white;
}

.cultureContain h2 {
    font-size: 1.5em;
    font-weight: bold;
}

.valuesSection {
    position: relative;
    font-size: 1.6rem;
}

.valuesSection #valuesBackground {
    width: 100%;
}

.valuesContain {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

#valuesImage {
    position: absolute;
    object-fit: cover;
    top: 12.3%;
    left: 34.8%;
    border-radius: 50%;
    width: 30.5%;
    height: 72.5%;
}

#valuesText {
    position: absolute;
    top: 60%;
    left: 39%;
    width: 23%;
}

.valuesDiv {
    color: white;
    width: 30%;
    height: 30%;
    margin: 4%;
}

.valuesDiv h4{
    font-size: 1.8em;
    font-weight: bold;
}


@media screen and (max-width: 700px)
{
    .informationLines {
        flex-direction: column;
    }

    .cultureSection {
        font-size: 1.4rem;
    }

    .cultureSection img {
        height: 300px;
        object-fit: cover;
        object-position: 50% 25%;
    }
}