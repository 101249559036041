body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%;
}

.modal-content .modal-title {
  font-size: 3rem;
}

.modal-content .modal-body {
  font-size: 1.6rem;
}

h1 {
  text-align: center;
}

.blue {
  color: #0082CA;
}

.purple {
  color: #7E4082;
}

.green {
  color: #6CC04A;
}

.orange {
  color: #F38B00;
}

.blueBackground {
  background-color: #0082CA;
}

.purpleBackground {
  background-color: #7E4082;
}

.greenBackground {
  background-color: #6CC04A;
}

.orangeBackground {
  background-color: #F38B00;
}