.basketSection {
    position: fixed;
    top: 100px;
    right: 0;
    width: 25%;
    padding: 1.5rem;
    border-radius: 2%;
    color: white;
    background-color: #F38B00;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
}

.basketSection h2 {
    font-size: 2em;
}

.basketSection button {
    background-color: #F38B00;
    border: none;
}

.basketSection .customButton {
    font-size: 1em;
    background-color: white;
    border-radius: 20px;
    /* height: 2em; */
    margin: 0.5em;
}

.basketSection table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5em;
}

.basketSection td {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
}

.basketSection th {
    text-align: left;
}