header {
    position: fixed;
    width: 100%;
    font-size: 1.6rem;
    z-index: 20;
}

#backgroundMenu {
    width: 100%;
    height: 100px;
}

.menuContain {
    position: absolute;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}


.buttonSection {
    position: absolute;
    left: 32%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.buttonSection a {
    width: 16.66%;
    margin: 0 0.5em;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.buttonSection .selected {
    opacity: 0.5;
}


.loginButtonSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.loginButtonSection p {
    margin: 0;
}

.loginButtonSection img {
    margin-left: 0.8em;
    height: 1.4em;
}

.loginButtonSecondText {
    font-size: 0.7em;
}

#basket {
    height: 50px;
}

.basketButton {
    position: absolute;
    right: 0;
    margin: 15px;
    background-color: transparent;
    border: none;
}

.homeLink {
    position: absolute;
    left: 15%;
    height: 90%;
    width: 200px;
}

.ergoLogo {
    width: 100%;
    height: 100%;
}

.menuButton {
    position: absolute;
    left: 2%;
    display: flex;
    align-items: center;
    color: #0082ca;
    font-weight: 500;

    background: inherit;
    border: none;
}

.menuButton p {
    margin: 0 0 0 0.5em;
}



@media screen and (max-width: 1200px)
{
    .loginButtonSecondText {
        display: none;
    }
}


@media screen and (max-width: 1000px)
{
    .buttonSection {
        left: 12%;
        width: 80%;
    }

    .loginButtonSection img {
        display: none;
    }

    .menuButton {
        left: 4%;
    }

    .menuButton p {
        display: none;
    }

    .homeLink {
        display: none;
    }
}



@media screen and (max-width: 700px)
{
    .buttonSection {
        flex-direction: column;
        top: 30%;
        left: 35%;
        width: 60%;
        border-left: 1px solid white;
    }

    
    .buttonSection a {
        width: 100%;
        margin: 0;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
    }

    .menuButton {
        left: 0.5%;
    }

    .homeLink {
        display: inline-block;
        left: auto;
        width: 150px;
    }

}


