.ordersBody {
    font-size: 1.6rem;
}

.ordersSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.orderCard {
    width: 300px;
    margin: 20px;
    padding: 1.5rem;
    border-radius: 2%;
    color: white;
    background-color: #F38B00;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.orderCard h4 {
    font-size: 1.5em;
    text-align: center;
}


.orderCard table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5em;
}

.orderCard td {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
}

.orderCard th {
    text-align: left;
}