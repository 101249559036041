.registerSection {
    width: 350px;
    color: white;
    font-size: 1.6rem;
}

.registerHeader {
    background-color: #F38B00;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    font-weight: bold;
    padding: 20px;
}

.registerForm {
    background-color: #2C3E50;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.registerForm .textInput {
    margin: 15px;
    padding: 0 15px;
    height: 1.7em;
    width: 80%;
    /* max-width: 400px; */
    border: none;
    border-radius: 10px;
}

.registerForm .privacyConditionSection {
    margin: 15px;
    width: 90%;
}

.privacyConditionSection input {
    margin: 0 10px 0 0;
}

.privacyConditionSection a {
    color: #0082CA;
}

.registerForm .purpleButton {
    margin: 0 0 20px 0;
    border-color: black;
    border-radius: 15px;
    padding: 0 20px;
    background-color: #7E4082;
    color: white;
    font-weight: bold;
}