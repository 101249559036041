.contactFormSection {
    font-size: 1.6rem;
    color: white;
}

.registerForm h1 {
    margin: 1em;
    width: 100%;
    text-align: center;
}

.registerForm .formButtons {
    width: 80%;
    margin: 15px;
    display: flex;
    justify-content: space-around;
}