.myInfos, .myDogsInfos {
    width: 50%;
}

.myInfos {
    border-right: 1px black solid;
}


.myProfileSection {
    display: flex;
    background-color: #2C3E50;
}