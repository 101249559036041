.newsSection {
    width: 100%;
    height: 500px;
    font-size: 1.6rem;
    background-color: black;
}

.newsSectionImage {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

#newsSectionImage2 {
    object-position: 50% 85%;
}

.newsSection .carousel-caption {
    position: absolute;
    width: 40%;
    min-width: 220px;
    color: white;
    background-color: #7E4082;
    opacity: 0.8;
    top: 20%;
    z-index: 1;
    padding: 0 20px;

    right: auto;
    left: auto;
    bottom: auto;
    text-align: left;
}

.carousel-caption h4 {
    opacity: 1;
    text-transform: uppercase;
    font-size: 1.3em;
    margin: 1.5em 0;
}

.newsSection .right {
    right: 0;
}

.newsSection .left {
    left: 0;
}

.newsSection .purple {
    background-color: #7E4082;
}

.newsSection .orange {
    background-color: #F38B00;
}

.newsSection .blue {
    background-color: #0082CA;
}

.newsSection .green {
    background-color: #6CC04A;
}


@media screen and (max-width: 1000px)
{
    .newsSection {
        height: 400px;
    }

    .newsSectionImage {
        height: 400px;
    }
}

@media screen and (max-width: 700px)
{
    .newsSection {
        height: 300px;
        font-size: 1.4rem;
    }

    .newsSectionImage {
        height: 300px;
    }

}