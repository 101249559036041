.productCard {
    width: 25%;
    margin: 1.5rem;
    padding: 1.5rem;
    border-radius: 2%;
    color: white;
    background-color: #0082CA;
    /* background-color: #7E4082; */
    /* background-color: #B3B3B3; */
    /* background-color: #F38B00; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
}

.productCard h4 {
    font-size: 1.5em;
    margin: 0.5em;
}

.productCard img {
    width: 80%;
    border-radius: 2%;
}

.productQuantity {
    width: 100%;
    margin: 1em 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productQuantity label {
    margin: 0 1em;
}

.productQuantity input {
    height: 2.5em;
    width: 6em;
    margin: 0 1em;
    background-color: white;
    border: none;
    border-radius: 1rem;
    text-align: center;
}

.productCard button {
    height: 2.5em;
    background-color: white;
    border: none;
    border-radius: 1rem;
}