
.presentationSection {
    position: relative;
    font-size: 1.6rem;
}

.presentationSection img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.presentationSection .presentationText {
    position: absolute;
    width: 40%;
    min-width: 250px;
    color: white;
    background-color: #7c4182;
    opacity: 0.8;
    top: 20%;
    z-index: 1;
    padding: 1em;
}

.presentationSection h4 {
    opacity: 1;
    text-transform: uppercase;
    font-size: 1.3em;
}


.presentationSection .presentationText2 {
    right: 0;
    background-color: #0082cb;
}

.presentationSection #dogInGrass {
    object-position: 0;
}

.foodTypeSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 1.6rem;
}

.foodTypes {
    width: 33%;
}

.foodTypes img {
    width: 100%;
}

.foodTypes p {
    text-align: center;
    font-weight: bold;
    margin: 0 0 4em 0;
}

#adultKibbles a {
    text-decoration: none;
    color: #0082ca;
}

#littleAdultKibbles a {
    text-decoration: none;
    color: #7c4182;
}

#puppyKibbles a {
    text-decoration: none;
    color: #6bc04b;
}



@media screen and (max-width: 1000px)
{
    .presentationSection img {
        height: 400px;
    }

}

@media screen and (max-width: 700px)
{
    .foodTypes {
        width: 250px;
    }

    .presentationSection img {
        height: 300px;
    }

    .presentationSection {
        font-size: 1.3rem;
    }
}

