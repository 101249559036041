.footerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #313332;
    color: white;
    font-size: 1.6rem;
    padding: 3%;
}

.ergoLogoBlock {
    width: 25%;
}

.ergoLogoBlock img {
    width: 100%;
}

.linkSection {
    width: 72.5%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.linkBlock {
    margin: 15px;
}

.linkBlock h5 {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 1em;
}

.linkBlock ul {
    list-style: none;
    padding-inline-start: 0;
}

.socialMediaLogos {
    margin: 0 0.4em;
}

.copyrightsSection {
    font-size: 1.8rem;
    text-align: center;
    color: white;
}

.copyrightsSection p {
    padding: 1em 0;
    margin: 0;
}

@media screen and (max-width: 1000px)
{
    .ergoLogoBlock {
        display: none;
    }

    .linkSection {
        width: 100%;
        justify-content: left;
    }

    .linkBlock {
        margin: 20px;
    }
}