.paymentSection {
    font-size: 1.6rem;
    margin: 30px 0;
}

.paymentSection2 {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    border-radius: 10px;
    padding: 20px;
}

.paymentSection2 h2 {
    margin: 1em 0;
}