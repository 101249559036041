.emptyDiv {
    height: 100px;
}

.bubbleSection {
    padding: 50px 0 100px 0;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.bubble {
    width: 250px;
    margin: 20px;
    text-align: center;
}

.bubble img {
    width: 60%;
}

.bubble p {
    margin: 0;
}

#bubbleText1 strong {
    color: #6CC04A;
}

#bubbleText2 strong {
    color: #7E4082;
}

#bubbleText3 strong {
    color: #0082CA;
}

#bubbleText4 strong {
    color: #F38B00;
}

.scientificSection {
    width: 100%;
    align-items: center;
    font-size: 1.6rem;
}

.scientificSection h2 {
    text-align: center;
}

.scientificSection2 {
    position: relative;
}

.scientificSection #blueBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.scientificSectionContain {
    display: flex;
}

.scientificSectionText, .scientificSectionKibbles {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.scientificSectionKibbles img {
    width: 80%;
    margin: 10%;
}

.scientificSection ul {
    list-style-image: url('SVG-icons/paw.svg');
    margin: 5%;
    color: white;
}

.scientificSection li {
    margin: 5px;
}

.formula {
    width: 100%;
    display: flex;
    background-color: white;
    font-size: 1.6rem;
}

.formulaImage, .formulaText {
    width: 50%;
}

.formulaImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.formulaImage img {
    width: 90%;
}

.formulaText {
    margin: 20px;
    color: #7c4182;
}

.formulaTitle {
    display: flex;
    align-items: center;
}

.formulaTitle img {
    height: 5em;
    margin: 0 2em;
}

.formulaText ul {
    padding-inline-start: 20px;
}

.formulaText li {
    margin: 1.5em;
}

.localSection {
    position: relative;
    display: flex;
    font-size: 1.6rem;
}

.localSection #girlWalkingHerDog {
    width: 100%;
}

.localSectionContain {
    position: absolute;
    right: 0;
    width: 50%;
    padding: 0 4%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    color: white;
    font-weight: bold;
    text-align: right;        
}

.localSectionContain p {
    margin-top: 20%;
}

.localSectionContain img {
    width: 85%;
}


@media screen and (max-width: 1000px)
{
    .scientificSectionText {
        width: 100%;
    }

    .scientificSectionKibbles {
        display: none;
    }
}


@media screen and (max-width: 700px)
{
    .scientificSection {
        font-size: 1.4rem;
    }
    
    .formulaImage {
        display: none;
    }

    .formulaText {
        width: 100%;
    }

    .formulaText ul {
        padding-inline-start: 5px;
    }

    .localSection #girlWalkingHerDog {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }

    .localSectionContain {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .localSectionContain img {
        width: 65%;
    }

    .localSectionContain p {
        margin-top: 0;
    }

}