.basketPage {
    display: flex;
    justify-content: center;
    align-items: center;
}


.basketPage .basketSection {
    position: static;
    margin: 60px;
    width: 60%;
}