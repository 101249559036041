.productSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

section {
    background-color: white;
    position: relative;
}

.mainSection {
    width: 75%;
}